// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-campaign-page-js": () => import("./../../../src/templates/CampaignPage.js" /* webpackChunkName: "component---src-templates-campaign-page-js" */),
  "component---src-templates-consumer-apple-watch-landing-page-js": () => import("./../../../src/templates/consumer/AppleWatchLandingPage.js" /* webpackChunkName: "component---src-templates-consumer-apple-watch-landing-page-js" */),
  "component---src-templates-consumer-consumer-home-page-js": () => import("./../../../src/templates/consumer/ConsumerHomePage.js" /* webpackChunkName: "component---src-templates-consumer-consumer-home-page-js" */),
  "component---src-templates-consumer-i-pad-landing-page-js": () => import("./../../../src/templates/consumer/IPadLandingPage.js" /* webpackChunkName: "component---src-templates-consumer-i-pad-landing-page-js" */),
  "component---src-templates-consumer-my-truphone-landing-page-js": () => import("./../../../src/templates/consumer/MyTruphoneLandingPage.js" /* webpackChunkName: "component---src-templates-consumer-my-truphone-landing-page-js" */),
  "component---src-templates-get-started-page-js": () => import("./../../../src/templates/GetStartedPage.js" /* webpackChunkName: "component---src-templates-get-started-page-js" */),
  "component---src-templates-legal-legal-article-js": () => import("./../../../src/templates/legal/LegalArticle.js" /* webpackChunkName: "component---src-templates-legal-legal-article-js" */),
  "component---src-templates-legal-legal-index-js": () => import("./../../../src/templates/legal/LegalIndex.js" /* webpackChunkName: "component---src-templates-legal-legal-index-js" */),
  "component---src-templates-new-component-page-js": () => import("./../../../src/templates/NewComponentPage.js" /* webpackChunkName: "component---src-templates-new-component-page-js" */),
  "component---src-templates-support-contact-support-js": () => import("./../../../src/templates/support/ContactSupport.js" /* webpackChunkName: "component---src-templates-support-contact-support-js" */),
  "component---src-templates-support-support-article-js": () => import("./../../../src/templates/support/SupportArticle.js" /* webpackChunkName: "component---src-templates-support-support-article-js" */),
  "component---src-templates-support-support-index-js": () => import("./../../../src/templates/support/SupportIndex.js" /* webpackChunkName: "component---src-templates-support-support-index-js" */),
  "component---src-templates-support-support-product-index-js": () => import("./../../../src/templates/support/SupportProductIndex.js" /* webpackChunkName: "component---src-templates-support-support-product-index-js" */)
}

