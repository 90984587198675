import './static/fonts.css'
import './static/style.css'
import '@fontsource/sora'
import '@fontsource/sora/300.css'
import '@fontsource/sora/400.css'
import '@fontsource/sora/500.css'
import '@fontsource/sora/600.css'
import '@fontsource/sora/700.css'

export const onRouteUpdate = ({ location }) => scrollToAnchor(location)

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location, mainNavHeight = 0) {
  // Check for location so build does not fail
  if (location && location.hash) {
    setTimeout(() => {
      const item = document.querySelector(`${location.hash}`)
      if (item) {
        window.scrollTo({
          top: item.offsetTop - mainNavHeight,
          behavior: 'auto'
        })
      }
    }, 200)
  }

  return true
}
