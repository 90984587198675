const shadows = [
  'none',
  '0 0 2px 0 rgba(0, 0, 0, 0.08), 0 2px 2px 0 rgba(0, 0, 0, 0.05)',
  '0 1px 10px 0 rgba(0, 0, 0, 0.08), 0 4px 5px 0 rgba(0, 0, 0, 0.05)',
  '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
  '0px 60px 80px 0 rgba(0, 0, 0, 0.1), 20px 20px 40px 0 rgba(0, 0, 0, 0.1)',
  '0 20px 60px 0 rgba(0, 0, 0, 0.08), 0 20px 40px 0 rgba(0, 0, 0, 0.05)',
  '20px 20px 40px 0 rgba(0, 0, 0, 0.1), 60px 80px 80px 0 rgba(0, 0, 0, 0.1)',
  '0 3px 14px 0 rgba(0, 0, 0, 0.08), 0 8px 10px 0 rgba(0, 0, 0, 0.05)',
  '0 4px 5px 0 rgba(13, 49, 63, 0.05), 0 1px 5px 0 rgba(13, 49, 63, 0.05)',
  '0 1px 3px 0 rgba(1, 49, 63, 0.3), 2px 2px 10px 0 rgba(1, 49, 63, 0.3)',
  '0 13px 38px 0 rgba(0, 0, 0, 0.08), 0 13px 25px 0 rgba(0, 0, 0, 0.05)'
]

export default shadows
