import { createTheme } from '@material-ui/core'
import overrides from './overrides'
import colors from './colors'
import shadows from './shadows'
import typography from './mytypography'

const muiTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1248,
      xl: 1920
    }
  },
  shadows,
  colors,
  typography,
  spacing: 8,
  palette: {
    primary: { main: colors.blue1, contrastText: colors.white },
    secondary: { main: colors.blue5, contrastText: colors.white },
    error: { main: colors.error, contrastText: colors.white },
    success: { main: colors.success, contrastText: colors.white },
    info: { main: colors.grey1, contrastText: colors.blue5 },
    planStatus: { main: colors.blue1, contrastText: colors.white, notActive: colors.grey9 },
    profileVerification: { verified: colors.blue1, notVerified: colors.error },
    text: {
      primary: colors.blue5,
      secondary: 'rgba(13, 49, 63, 0.6)',
      disabled: 'rgba(13, 49, 63, 0.4)',
      hint: 'rgba(13, 49, 63, 0.6)',
      light: colors.white
    },
    background: {
      paper: colors.white,
      default: colors.white
    }
  },
  overrides: overrides()
}

const theme = {
  //...TruphoneTheme,
  ...muiTheme
}

export default createTheme(theme)
