const colors = {
  // truphone blue tones
  blue1: '#2a9ec2',
  blue2: '#30819e',
  blue3: '#20667f',
  blue4: '#104c5f',
  blue5: '#01313f',
  blue6: '#2e3032',
  blue7: '#48abc9',

  secondary: '#01313f',
  pink: '#ff6584',

  // truphone neutral tones
  cream: '#f7f6f6',

  // truphone grey tones
  grey1: '#f3f6f6',
  grey2: '#e8ecee',
  grey3: '#dde3e5',
  grey4: '#d1d9dc',
  grey5: '#c6ccce',
  grey7: '#a1a0a1',
  grey9: '#767c7e',

  black: '#000000',
  white: '#ffffff',

  borderColor: 'rgba(0, 0, 0, 0.23)',

  colorTransparent: 'rgba(13, 49, 63, .5)',
  colorTransparent1: 'rgba(16, 76, 95, .1)',
  colorTransparent2: 'rgba(16, 76, 95, .2)',
  colorTransparent3: 'rgba(16, 76, 95, .3)',
  colorTransparent4: 'rgba(16, 76, 95, .4)',
  colorTransparent6: 'rgba(13, 49, 63, .6)',

  whiteTransparent: 'rgba(255, 255, 255, .4)',
  whiteTransparent1: 'rgba(255, 255, 255, .1)',
  whiteTransparent6: 'rgba(255, 255, 255, .6)',

  // truphone context colors
  success: '#04c453',
  error: '#e74243',
  warning: '#f57c00',

  hover: '#67b2ce'
}

export default colors
