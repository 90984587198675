import colors from './colors'
//import fonts from './fonts'
import shadows from './shadows'

export default () => {
  return {
    MuiCssBaseline: {
      '@global': {
        //'@font-face': [...fonts]
      }
    },
    MuiCardContent: {
      root: {
        padding: 28
      }
    },
    MuiInputBase: {
      input: {
        '&:-internal-autofill-selected': {
          backgroundColor: 'transparent !important'
        }
      }
    },
    MuiLink: {
      root: {
        color: 'inherit',
        textDecoration: 'none',
        cursor: 'pointer'
      }
    },
    MuiButton: {
      root: {
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '1.5px',
        padding: '0 1rem',
        height: '47px',
        boxShadow: 'none',
        transition: 'all .3s ease-in-out',
        '& span': {
          zIndex: 1
        }
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: '2px 2px 10px 0 rgba(1, 49, 63, 0.3), 0 1px 3px 0 rgba(1, 49, 63, 0.3)'
        },
        '&:hover': {
          boxShadow: '2px 2px 10px 0 rgba(1, 49, 63, 0.3), 0 1px 3px 0 rgba(1, 49, 63, 0.3)'
        }
      },
      containedSecondary: {
        backgroundImage: `linear-gradient(100deg, ${colors.blue5}, ${colors.blue5})`,
        '&:hover': {
          color: colors.white,
          backgroundImage: `linear-gradient(100deg, ${colors.blue4}, ${colors.blue5})`
        }
      },
      containedPrimary: {
        backgroundImage: 'linear-gradient(105deg, #299dc1, #299dc1)',
        '&:hover': {
          color: colors.white,
          backgroundImage: 'linear-gradient(105deg, #16bce6, #299dc1)',
          boxShadow: '2px 2px 10px 0 rgba(41, 157, 193, 0.3), 0 1px 3px 0 rgba(41, 157, 193, 0.3)'
        }
      },
      outlined: {
        height: '47px',
        padding: '0 1rem',
        border: '2px solid ' + colors.grey2,
        color: 'currentColor',
        position: 'relative',
        '&::after': {
          top: 0,
          left: '-1%',
          width: '102%',
          height: '101%',
          content: '""',
          opacity: 0.5,
          position: 'absolute',
          backgroundColor: 'transparent',
          transition: 'backgroundColor 0.3s'
        },
        '&:hover': {
          color: 'currentColor',
          backgroundColor: 'rgba(255, 255, 255, .3)',
          //boxShadow: '2px 2px 10px 0 rgba(232, 236, 238, .2), 0 1px 3px 0 rgba(232, 236, 238, .2)'
          '&::after': {
            backgroundColor: colors.grey2
          }
        }
      },
      outlinedSecondary: {
        border: '2px solid' + colors.blue5,
        '&:hover': {
          color: colors.blue5,
          border: '2px solid ' + colors.blue5
        }
      },
      sizeLarge: {
        padding: '0 2rem',
        lineHeight: 1.2,
        height: '47px',
        minWidth: '12rem'
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: colors.blue4,
        opacity: 0.1
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0 4px 5px 0 rgba(13, 49, 63, 0.05), 0 1px 5px 0 rgba(13, 49, 63, 0.05)'
      }
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: colors.blue5 + '!important'
        }
      },
      input: {
        padding: '11.5px 14px'
      }
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: colors.blue5
        }
      },
      outlined: {
        transform: 'translate(14px, 14px) scale(1)'
      }
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: colors.blue5
      }
    },
    MuiTabs: {
      root: {
        borderBottom: '1px solid' + colors.grey3
      },
      flexContainer: {
        backgroundColor: colors.white
      }
    },
    MuiTab: {
      root: {
        letterSpacing: '1.5px'
      },
      fullWidth: {
        whiteSpace: 'nowrap',
        flexBasis: 'auto',
        flexShrink: 0
      }
    },
    MuiMenuItem: {
      root: {
        minHeight: 36,

        '@media (min-width: 600px)': {
          minHeight: 36
        }
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent'
        }
      },
      icon: {
        color: colors.blue5,
        opacity: 0.4
      }
    },
    MuiPopover: {
      paper: {
        boxShadow: shadows[3]
      }
    },
    MuiMenu: {
      paper: {
        maxHeight: 350
      }
    },
    MuiGrid: {
      container: {
        maxWidth: 'none !important'
      }
    }
  }
}
